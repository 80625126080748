@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@700&display=swap');
body {
  font-family: "DM Sans", sans-serif;
}

.colorpicker {
  background: transparent!important;
  box-shadow: none!important;
  border: none!important;
}

option {
  color: black;
}

.fader {
  transition: .5s all;
}

.link-select {
  width: 100%;
  cursor: pointer!important;
}

.icon-select {
  cursor: pointer!important;
}